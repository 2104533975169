













































































import { mapGetters } from 'vuex';
import Vue from 'vue';
import PaymentMethods from '@/models/enums/PaymentMethods';
import BoletoFinishCheckout from '@/components/finish-checkout/BoletoFinishCheckout.vue';
import PixFinishCheckout from '@/components/finish-checkout/PixFinishCheckout.vue';
import OrderSummary from '@/components/OrderSummary.vue';

export default Vue.extend({
  components: { BoletoFinishCheckout, PixFinishCheckout, OrderSummary },
  name: 'CheckoutFinished',
  computed: {
    ...mapGetters(['tenantUrls']),
    ...mapGetters(['checkoutPlan']),
    ...mapGetters(['checkoutCoupon']),
    ...mapGetters(['transactionResult']),
    nextButtonLabel() {
      switch (this.paymentMethod) {
        case PaymentMethods.BOLETO:
          return this.$t('checkout.next_boleto_payment');
        case PaymentMethods.PIX:
          return this.$t('checkout.next_pix_paymeny');
        default:
          return this.$t('checkout.finish');
      }
    },
    isTransactionCompleted() {
      return this.transactionResult.transactionStatus === 'paid';
    },
    paymentStepLabel() {
      const stepLabel = this.isTransactionCompleted ? 'payment_done' : 'waiting_payment';
      return this.$t(`checkout_finish.stepper.${stepLabel}`);
    },
    discountAmount() {
      if (!this.checkoutPlan) return 0;
      const fullPrice = this.checkoutCoupon?.data
        ? this.checkoutCoupon.data.fee : this.checkoutPlan.fee;
      return fullPrice - (this.transactionResult.totalAmount / 100.0);
    },
  },
  data() {
    return {};
  },
  methods: {
    goToLandingPage() {
      window.location.href = this.tenantUrls.landingPage as string;
    },
  },
});
