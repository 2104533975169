
































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PlatformButton from '../base-components/PlatformButton.vue';

export default Vue.extend({
  components: { PlatformButton },
  name: 'BoletoFinishCheckout',
  computed: {
    ...mapGetters(['transactionResult']),
  },
  // data() {
  //   return {
  //     transactionResult: {
  //       transactionStatus: 'waiting_payment',
  //       transactionStatusReason: 'acquirer',
  //       paymentMethod: 'boleto',
  //       boletoUrl: 'https://pagar.me',
  //       boletoBarcode: '1234 5678',
  //       beletoExpirationDate: '2021-11-04T03:00:00.000Z',
  //       pixQrCode: null,
  //       pixExpirationDate: null,
  //     },
  //   };
  // },
  methods: {
    openBoletoUrl() {
      window.open(this.transactionResult.boletoUrl, '_blank');
    },
    copyBoletoToClipboard() {
      navigator.clipboard.writeText(this.transactionResult.boletoBarcode);
    },
  },
});
