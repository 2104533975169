



























import Vue from 'vue';
import QrcodeVue from 'qrcode.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { QrcodeVue },
  name: 'PixFinishCheckout',
  computed: {
    ...mapGetters(['transactionResult']),
  },
  // data() {
  //   return {
  //     transactionResult: {
  //       transactionStatus: 'waiting_payment',
  //       transactionStatusReason: 'acquirer',
  //       paymentMethod: 'pix',
  //       boletoUrl: null,
  //       boletoBarcode: null,
  //       beletoExpirationDate: null,
  //       pixExpirationDate: '2021-11-10T02:59:59.000Z',
  //     },
  //   };
  // },
  methods: {
    copyPixKey() {
      navigator.clipboard.writeText(this.transactionResult.pixQrCode);
    },
  },
});
